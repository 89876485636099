import { Button, Divider, Input, InputNumber, InputNumberProps, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

type Props = {
  elements: any[];
  keys: any[];
};
const Correspondance = ({ elements, keys }: Props) => {
  const [mapping, setMapping] = useState<any[]>([]);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [compteCredit, setCompteCredit] = useState<string>("");
  const [compteDebit, setCompteDebit] = useState<string>("");
  const [numLigne, setNumLigne] = useState<any>(0);
  const [numPiece, setNumPiece] = useState<any>(0);
  const [code, setCodeJournal] = useState<any>("");

  const elementsBanque = [
    "Num Pièce",
    "Num Ligne",
    "Compte",
    "Contrepartie",
    "Libellé",
    "Sens",
    "Mnt Origine",
    "Mnt USD",
    "Code Jrl",
    "Date Opér.",
    "Num Facture",
  ];
  const tableRef = useRef(null);

  const updateMapping = (key: number, isDefault: boolean, value: string) => {
    const list = [...mapping];
    list[key].isDefault = isDefault;
    list[key].value = value;
    setMapping(list);
  };

  const getValue = (x: string, element: any): string => {
    const searched = mapping.filter((el) => el.name === x)[0];
    return searched.isDefault ? searched.value : element[searched.value];
  };

  const prepareData = () => {
    const data: any[] = [];
    var piece = numPiece;
    var ligne = numLigne;
    elements.forEach((element) => {
      console.log(element["Débit"]);

      var json = {
        "Num Pièce": piece,
        "Num Ligne": ligne,
        Compte: compteDebit,
        Contrepartie: "",
        Libellé: element["Libellé"],
        Sens: element["Débit"] != "" ? "D" : "C",
        "Mnt Origine": element["Débit"] != "" ? element["Débit"] : element["Crédit"],
        "Mnt USD": "",
        "Code Jrl": code,
        "Date Opér.": element["Date"],
        "Num Facture": "",
      };
      var json2 = {
        "Num Pièce": piece,
        "Num Ligne": ligne + 1,
        Compte: compteCredit,
        Contrepartie: "",
        Libellé: element["Libellé"],
        Sens: element["Débit"] == "" ? "D" : "C",
        "Mnt Origine": element["Débit"] != "" ? element["Débit"] : element["Crédit"],
        "Mnt USD": "",
        "Code Jrl": code,
        "Date Opér.": element["Date"],
        "Num Facture": "",
      };

      data.push(json);
      data.push(json2);
      piece++;
    });
    setMappedData(data);
  };

  const generateFile = () => {
    var mappedData: any[] = [];
    let numL = 2;

    elements.forEach((el: any) => {
      var json: any = {};
      elementsBanque.forEach((x) => {
        if (x == "Num Pièce") {
          json[x] = numL;
          numL++;
        } else {
          json[x] = getValue(x, el);
        }
      });
      mappedData.push(json);
    });
    setMappedData(mappedData);
  };

  useEffect(() => {
    const list: any[] = [];
    elementsBanque.map((el) => {
      list.push({ name: el, value: "", isDefault: true });
    });
    setMapping(list);
  }, []);

  const onChange: InputNumberProps["onChange"] = (value) => {
    setNumLigne(value ?? 0);
  };

  const onChangeP: InputNumberProps["onChange"] = (value) => {
    setNumPiece(value);
  };

  return (
    <>
      <Title level={3}>Correspondance</Title>
      <table>
        <tr>
          <td style={{ width: "200px" }}>Compte Crédit</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCompteCredit(e.target.value)} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Compte Débit</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCompteDebit(e.target.value)} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Num de Pièce</td>
          <td style={{ width: "200px" }}>
            <InputNumber onChange={onChangeP} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Num de ligne</td>
          <td style={{ width: "200px" }}>
            <InputNumber onChange={onChange} />
          </td>
        </tr>
        <tr>
          <td style={{ width: "200px" }}>Code journal</td>
          <td style={{ width: "200px" }}>
            <Input onChange={(e) => setCodeJournal(e.target.value)} />
          </td>
        </tr>
        <tr>
          <Divider />
        </tr>

        <tr>
          <th>Valeur fichier destination</th>
          <th>Valeur fichier source</th>
          <th>Fixe value</th>
        </tr>
        {/* {elementsBanque.map((el: any, key: number) => (
          <tr>
            <td style={{ width: "200px" }}>{el}</td>
            <td style={{ width: "200px" }}>
              <Select style={{ width: "200px" }} onChange={(e) => updateMapping(key, false, e)}>
                {keys.map((x) => (
                  <Select.Option value={x}>{x}</Select.Option>
                ))}
              </Select>
            </td>
            <td>
              <Input onChange={(e) => updateMapping(key, true, e.target.value)} />
            </td>
          </tr>
        ))} */}
      </table>
      <div style={{ marginTop: "20px" }}>
        <Button onClick={() => prepareData()}>Générer le fichier</Button>
        <DownloadTableExcel filename='table' sheet='table' currentTableRef={tableRef.current}>
          <Button type='primary' disabled={mappedData.length === 0}>
            Exporter le résultat
          </Button>
        </DownloadTableExcel>

        <table ref={tableRef} style={{ marginTop: "20px" }}>
          <tbody>
            {mappedData.length > 0 && (
              <>
                <tr>
                  {elementsBanque.map((el, key) => (
                    <th style={{ width: "200px" }}>{el}</th>
                  ))}
                </tr>
                {mappedData.map((line, lineKey) => (
                  <tr>
                    {elementsBanque.map((el, key) => (
                      <td style={{ width: "200px" }}>{line[el]}</td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Correspondance;
