import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Row, Skeleton, Steps, Table } from "antd";
import cookies from "react-cookies";
import { Layout } from "antd";
import { banques } from "../Data";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Hourglass } from "react-loader-spinner";
import Correspondance from "./Correspondance";
import { useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const gridStyle: React.CSSProperties = {
  width: "20%",
  textAlign: "center",
  cursor: "pointer",
  alignContent: "center",
  minHeight: "150px",
};

const Convertor = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [file, setFile] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [keys, setKeys] = useState<any[]>([]);
  const handleLogout = () => {
    cookies.remove("token");
    navigate("/");
  };
  const [loading, setLoading] = useState<boolean>(false);
  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "text/pdf": [".pdf"],
    },
    maxFiles: 1,
    onDrop,
  });

  const baseStyle = {
    marginTop: "20px",
    minHeight: "200px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const getRows = (data: any) => {
    return data.map((obj: any) => {
      return <tr>{getCells(obj)}</tr>;
    });
  };
  const getHeadings = (data: any) => {
    return Object.keys(data[0]).map((key) => {
      return <th className='ant-table-cell'>{key}</th>;
    });
  };

  const getJsonHeader = (data: any) => {
    return Object.keys(data[0]).map((key) => {
      return { title: key, dataIndex: key };
    });
  };

  // Return an array of cell data using the
  // values of each object
  const getCells = (obj: any) => {
    return Object.values(obj).map((value: any) => {
      return <td className='ant-table-cell'> {value}</td>;
    });
  };

  useEffect(() => {
    if (step === 2) {
      toConvertData(active, file);
    }
  }, [step]);

  const toConvertData = (active: string, file: any) => {
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("bank", active);
    bodyFormData.append("file", file);

    axios
      .post(process.env.REACT_APP_API_URL + "convert", bodyFormData)

      .then(function (response) {
        setData(response.data.list);
        setKeys(response.data.keys);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  return (
    <div>
      <Layout style={{ padding: "20px" }}>
        <div style={{ textAlign: "right", marginBottom: "20px" }}>
          <Button
            style={{
              backgroundColor: "#0051cc",
              color: "#fff",
              borderColor: "#0051cc",
              padding: "1.2rem",
              fontSize: "0.9rem",
              fontWeight: 600,
            }}
            onClick={handleLogout}>
            Se déconnecter
          </Button>
        </div>
        {/* <Header
          style={{
            position: "sticky",
            top: 0,
            background: "#fff",
            marginBottom: "20px",
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="demo-logo" />
        </Header> */}
        <Steps
          current={step + 1}
          items={[
            {
              title: "Choisir la banque",
              description: "On accepte les banques suivants ",
            },
            {
              title: "Choisir votre Fichier",
              description: "Seul les fichier PDF sont acceptés",
            },
            {
              title: "Résultat",
              description: "",
            },
          ]}
        />

        <Content style={{ minHeight: 700, padding: "0 48px", marginTop: "20px" }}>
          {step === 0 && (
            <div
              style={{
                padding: 24,
              }}>
              <Card title='Choisissez la banque'>
                {banques.map((el) => (
                  <Card.Grid style={gridStyle} className={active === el.name ? "grid-active" : ""} onClick={() => setActive(el.name)}>
                    <img style={{ width: el.width }} src={`/images/${el.image}`} alt='' />
                  </Card.Grid>
                ))}
              </Card>
            </div>
          )}
          {step === 1 && (
            <div className='container'>
              <h1>Banque: {active.toUpperCase()}</h1>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Ajouter votre fichier PDF</p>
              </div>
            </div>
          )}
          {step === 2 && (
            <>
              <h1>Banque: {active.toUpperCase()}</h1>

              {loading ? (
                <Skeleton />
              ) : (
                data.length > 0 && (
                  // <table className='ant-table'>
                  //   <thead className='ant-table-thead'>{getHeadings(data)}</thead>
                  //   <tbody className='ant-table-tbody'>{getRows(data)}</tbody>
                  // </table>
                  <Table columns={getJsonHeader(data)} dataSource={data} />
                )
              )}
              <Correspondance keys={keys} elements={data} />
            </>
          )}
          {step === 0 && active != "" && (
            <Row justify={"center"}>
              <Button type='primary' onClick={() => setStep(step + 1)}>
                Suivant
              </Button>
            </Row>
          )}
          {step === 1 && file != null && (
            <Row justify={"center"}>
              <Button type='primary' onClick={() => setStep(step + 1)}>
                Suivant
              </Button>
            </Row>
          )}
          {step === 2 && (
            <Row justify={"center"}>
              <Button type='primary' onClick={() => setStep(0)}>
                Recommencer
              </Button>
            </Row>
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>CODY ©{new Date().getFullYear()} </Footer>
      </Layout>
    </div>
  );
};

export default Convertor;
